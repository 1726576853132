import React from 'react'

import { logEvent } from '../utils/analytics'

const GetStarted = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()

    if (this.state.value) {
      const object = {
        category: 'Get Started',
        action: 'Submitted Get Started Form'
      }

      logEvent(object)

      window.location.href = `https://app.lake.finance?email=${this.state.value}`
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`dib relative w-100 f5 ${this.props.className}`}
      >
        <div className="dib flex-l justify-between middle-xs w-100">
          <input
            className="dib relative bg-near-white w-100 w-80-l pa3 br-pill mr0 mr3-l mb3 mb0-l"
            placeholder="Enter your email address"
            onChange={this.handleChange}
            value={this.state.value}
            type="email"
          />
          <button
            className="dib relative w-100 w-20-l bg-brand-pink brand-red tc pa3 br-pill b link dim pointer"
            type="submit"
          >
            Start
          </button>
        </div>
      </form>
    )
  }
}

export default GetStarted
