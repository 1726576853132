import React from 'react'

// Modules
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'

// Component
import TeamMember from './team-member'

class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      members: []
    }
  }

  componentDidMount() {
    this.setState({
      members: this.props.members
    })
  }

  render() {
    const { members } = this.state

    return (
      <Swiper
        swiperOptions={{
          slidesOffsetAfter: 0,
          centeredSlides: true,
          slidesPerView: 5,
          spaceBetween: 40,
          // grabCursor: true,
          breakpoints: {
            // when window width is <= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            // when window width is <= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          },
          autoplay: {
            delay: 2000,
            disableOnInteraction: true
          },
          keyboard: {
            enabled: true
          }
        }}
        pagination={false}
        prevButton={<img className="prev-next prev" src="/icons/scroll-down.svg" alt="Previous" />}
        nextButton={<img className="prev-next next" src="/icons/scroll-down.svg" alt="Next" />}
      >
        {members.map(member => (
          <Slide {...member} key={Math.random()}>
            <TeamMember member={member} />
          </Slide>
        ))}
      </Swiper>
    )
  }
}

export default Team
