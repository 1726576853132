import React from 'react'
import { Link, graphql } from 'gatsby'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import GetStarted from '../components/get-started'
import Layout from '../containers/layout'
import Team from '../components/team'
import SEO from '../components/seo'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    home: sanityHome(_id: { regex: "/(drafts.|)homePage/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
      title2
      body2
      slide2Image {
        asset {
          _id
        }
      }
      steps {
        description
        title
        icon {
          asset {
            _id
          }
        }
      }
      titleTime
      slide3Description
      slide3Image {
        asset {
          _id
        }
      }
      safety
      safetyDescription
      safetySteps {
        description
        title
        icon {
          asset {
            _id
          }
        }
      }
      titlePlatform
      platformDescription
      platformImage {
        asset {
          _id
        }
      }
      titleTeam
      teamDescription
      teamCTA
      teamLink
      titleStart
      startDescription
      startImage {
        asset {
          _id
        }
      }
    }

    people: allSanityPerson(sort: { fields: [_createdAt], order: ASC }) {
      edges {
        node {
          id
          image {
            asset {
              _id
            }
          }
          slug {
            current
          }
          name
          position
          linkedin
          twitter
        }
      }
    }
  }
`

// Classes
const headingClass = 'dib relative w-100 measure f2 fw5 mt0 mb3'
const paragraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const people =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)
  const site = (data || {}).site
  const home = (data || {}).home

  if (!home) {
    throw new Error(
      'Missing "Home" page data. Open the studio at http://localhost:3333 and add "Home" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article>
        {/* Home */}
        <section className="dt relative vh-100 w-100 bg-white black lh-copy f5" id="home">
          <div className="dtc v-mid w-100 relative z-1">
            <PageHeader description={home.body} heading={home.title1} href="#explainer" />
          </div>
          <img
            className="dib absolute bottom-0 right-0 h-100-l scale header"
            src={imageUrlFor(buildImageObj(home.slide1Image))}
            alt="Lake"
          />
        </section>

        {/* Explainer */}
        <section className="dib relative w-100 black lh-copy f5" id="explainer">
          <div className="db center mw8 ph4 pt6 pb3">
            <h2 className={headingClass}>{home.title2}</h2>
            <p className={paragraphClass}>{home.body2}</p>
          </div>
          <img
            className="dib relative left-0 w-100 header mb4"
            src={imageUrlFor(buildImageObj(home.slide2Image))}
            alt="Built for fast-moving markets"
          />
          <div className="db center mw8 ph4 pv4">
            {home.steps.map(object => (
              <div className="dib flex-l justify-start w-100 items-center mb4" key={Math.random()}>
                <img
                  className="dib w4 w5-l scale mb4 mb0-l mr4 mr5-l"
                  src={imageUrlFor(buildImageObj(object.icon))}
                  alt={object.title}
                />
                <span className="dib relative measure-wide">
                  <strong className="f3 fw5">{object.title}</strong>
                  <p className="dib relative w-100 gray">{object.description}</p>
                </span>
              </div>
            ))}
          </div>
        </section>

        {/* Time */}
        <section className="dib relative w-100 black lh-copy f5 tc" id="time">
          <div className="dib relative w-100 bg-brand-beige z-1">
            <div className="db center mw8 ph4 pt5 pt6-ns pb0">
              <div className="content">
                <h3 className={headingClass}>{home.titleTime}</h3>
                <p className={`${paragraphClass} preline`}>{home.timeDescription}</p>
              </div>
            </div>
          </div>
          <img
            className="dib relative w-100 scale header"
            src={imageUrlFor(buildImageObj(home.slide3Image))}
            alt="More time for you"
          />
        </section>

        {/* Safety */}
        <section className="dib relative w-100 bg-white black lh-copy f5" id="safety">
          <div className="db center mw8 ph4 pv5 pv6-ns">
            <h4 className={headingClass}>{home.safety}</h4>
            <p className={paragraphClass}>{home.safetyDescription}</p>
            <div className="row top-xs">
              {home.safetySteps.map(object => (
                <div className="col-xs-12 col-md-4 mt4" key={Math.random()}>
                  <img
                    className="dib relative h4 scale mb4"
                    src={imageUrlFor(buildImageObj(object.icon))}
                    alt="More time for you"
                  />
                  <div className="dib relative w-100">
                    <strong className="f4 fw5">{object.title}</strong>
                    <p className="dib relative w-100 measure-wide gray fw4">{object.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Platform */}
        <section
          className="dib relative w-100 bg-black white lh-copy overflow-hidden f5"
          id="platform"
        >
          <div className="db center mw8 ph4 pv5 pv6-ns">
            <div className="row items-center">
              <div className="col-xs-12 col-md-8">
                <h5 className={headingClass}>{home.titlePlatform}</h5>
                <p className={paragraphClass}>{home.platformDescription}</p>
              </div>
              <div className="col-xs-12 col-md-4">
                <img
                  className="dib relative w-100"
                  src={imageUrlFor(buildImageObj(home.platformImage))}
                  alt="Check your portfolio anytime"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Team */}
        <section className="dib relative w-100 bg-white black lh-copy f5" id="team">
          <div className="db center mw8 ph4 pt5 pt6-ns pb4">
            <strong className={headingClass}>{home.titleTeam}</strong>
            <p className={paragraphClass}>{home.teamDescription}</p>
            <Link
              className="dib relative w-100 link dim inherit underline"
              to={home.teamLink || `/about`}
            >
              {home.teamCTA}
            </Link>
          </div>
          <div className="dib relative w-100">
            <Team members={people} />
          </div>
        </section>

        {/* Get started */}
        <section className="dib relative w-100 bg-white black lh-copy f5" id="start">
          <div className="db center mw8 ph4 pv5 pv6-ns relative">
            <div className="row middle-xs">
              <div className="col-xs-12 col-md-8">
                <h6 className={headingClass}>{home.titleStart}</h6>
                <p className={paragraphClass}>{home.startDescription}</p>
                <GetStarted className="dib relative w-100 measure-wide mb4 mb0-l" />
              </div>
              <div className="col-xs-12 col-md-4">
                <img
                  className="dib relative w-100 scale header"
                  src={imageUrlFor(buildImageObj(home.startImage))}
                  alt="Get started"
                />
              </div>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage
