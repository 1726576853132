import React from 'react'
import { Link } from 'gatsby'

import { logEvent } from '../utils/analytics'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const linkClass = 'dib relative v-bottom w-auto link dim gray ma0 f6 fw4'

class TeamMember extends React.Component {
  constructor(props) {
    super(props)

    this.logClick = this.logClick.bind(this)
  }

  logClick(name, dest) {
    const event = {
      category: 'Profile',
      action: `Clicked ${name}`,
      label: dest
    }
    logEvent(event)
  }

  render() {
    const { member } = this.props

    const Image = ({ image, alt }) => (
      <img className="dib relative w-100 scale" src={imageUrlFor(buildImageObj(image))} alt={alt} />
    )

    const Name = ({ name }) => (
      <strong className="dib relative w-100 f4 f3-l fw4 mb2">{name}</strong>
    )

    const Position = ({ position }) => (
      <p className="dib relative w-100 dark-gray mv0">{position}</p>
    )

    const LinkedIn = ({ name, href }) => (
      <a
        className={linkClass}
        onClick={() => {
          this.logClick(name, 'LinkedIn')
        }}
        target="_blank"
        href={href}
        rel="noopener noreferrer"
      >
        <img
          className="dib relative h1 w1 v-mid mr2"
          src="/icons/social/linkedin.svg"
          alt="LinkedIn"
        />
        <span className="dib relative v-mid">LinkedIn</span>
      </a>
    )

    const Twitter = ({ name, href }) => (
      <a
        className={linkClass}
        onClick={() => {
          this.logClick(name, 'Twitter')
        }}
        target="_blank"
        href={href}
        rel="noopener noreferrer"
      >
        <img
          className="dib relative h1 w1 v-mid mr2"
          src="/icons/social/twitter.svg"
          alt="Twitter"
        />
        <span className="dib relative v-mid">Twitter</span>
      </a>
    )

    return (
      <div className="dib relative w-100 member">
        {member.image ? <Image image={member.image} alt={member.name} /> : null}
        <Name name={member.name} />
        {member.position ? <Position position={member.position} /> : null}
        {member.linkedin ? (
          <span className="dib relative w-100">
            <LinkedIn name={member.name} href={member.linkedin} />
          </span>
        ) : null}
        {member.twitter ? (
          <span className="dib relative w-100">
            <Twitter name={member.name} href={member.twitter} />
          </span>
        ) : null}
        {member.slug && member.slug.current ? (
          <Link className={`${linkClass} mt3`} to={`/team/${member.slug.current}`}>
            Learn more
          </Link>
        ) : null}
      </div>
    )
  }
}

export default TeamMember
